import React from "react";
import winningPic from "../assets/winning-pic.png";
import Banner from "../components/Banner";
import classes from "./Home.module.css";

const Home = () => {
  return (
    <div>
      <Banner />
      <section>
        <center className={`${classes.statement_section} ${classes.container_one}`}>
          <div className={`${classes.print_text} ${classes.text_inverted}`}>
            <h3 className={classes.section_title}>PURPOSE DRIVEN NC ELITE</h3>
            <p>
              Purpose Driven NC Elite has a goal to create a training environment that will produce college bound athletes both academically and athletically through community involvement and youth awareness.
            </p>
            <a href='./about' className={classes.button}>LEARN MORE...</a>
          </div>

        </center>
      </section>
      <section>
        <center className={`${classes.statement_section} ${classes.statement_section_inverted} ${classes.container_one}`}>
          <div className={classes.print_text}>
            <h3 className={classes.section_title}>MISSION STATEMENT</h3>
            <p className={classes.mission_statement_text}>
              &ldquo;Encouraging our youth to chase their dreams through discipline, hard work and dedication. Just like a race on the track you must finish the race and everything you start in life&rdquo;
            </p>
          </div>
        </center>
      </section>
      <section className={classes.pic_container}>
        <div
          className="team-pic d-flex justify-content-center align-items-center"
          style={{
            backgroundImage: `url(${winningPic})`
          }}
        >
          <div className={classes.print_training_text}>
            {/* <p>
              Our athletes train in groups based on events (short sprints, long sprints, distance, and specialists - jumps/throws). We build our training calendars around 4 mesocycles - general preparation, specific preparation, pre-competition preparation, and competition preparation.
              We focus on developing the technical, physical, and psychological skills that ultimately improve athlete performance. Our staff uses a set of instructional cues to help athletes acquire the physical and mental acumen needed to improve.

            </p> */}
          </div>
          <div className={classes.shadow_container}></div>
        </div>
      </section>
      <section className={`${classes.statement_section} ${classes.statement_section_inverted} ${classes.container_one}`}>
        <center >
          <div className={classes.print_text}>
            <h3 className={classes.section_title}><span style={{ color: "#162b48" }}>LATEST</span> <span style={{ color: "red" }}>NEWS</span></h3>
            <p>
              Our athletes train in groups based on events (short sprints, long sprints, distance, and specialists - jumps/throws). We build our training calendars around 4 mesocycles - general preparation, specific preparation, pre-competition preparation, and competition preparation.
              We focus on developing the technical, physical, and psychological skills that ultimately improve athlete performance. Our staff uses a set of instructional cues to help athletes acquire the physical and mental acumen needed to improve.
            </p>
            <a href="/" className={`${classes.button} ${classes.button_inverted}`}>LEARN MORE...</a>
          </div>
        </center>
      </section >
    </div>
  );
};

export default Home;
