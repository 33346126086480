import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Image from 'react-bootstrap/Image';
import classes from "./NavBar.module.css";
import logo from "../assets/pd-logo.png";

const NavBar = () => {
  return (
    <>
      <Navbar collapseOnSelect fixed="top" key="md" expand="md" className={`${classes.custom_nav} nav-type}`} variant="dark" >
        <div className={classes.nav_container}>
          <Navbar.Brand href="/">
            <div className={classes.pd_logo_container}>
              <Image className={`${classes.pd_logo} img-fluid`} src={logo} alt="pd-logo" />
              <div className={classes.logo_text}>
                <h2>Purpose</h2>
                <h2>Driven</h2>
              </div>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="ms-auto" />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-md`}
            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                PURPOSE DRIVEN
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-5" activeKey={(selectedKey) => { return selectedKey ? { color: 'red' } : {} }}>
                <Nav.Link href="/">HOME</Nav.Link>
                <Nav.Link href="/register">REGISTER</Nav.Link>
                <Nav.Link href="/about">ABOUT US</Nav.Link>
                <Nav.Link href="/schedule">SCHEDULE</Nav.Link>
                <Nav.Link href="/contact">CONTACT US</Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </div>
      </Navbar>
    </>
  );
};

export default NavBar;
