import React from 'react'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <Container className={`${classes.footer_container} fluid pb-5 p-3`}>
      <Container className='pt-3'>
        <Row className='d-flex justify-content-between mb-5'>
          <Col className={`${classes.footer__col__item} ps-5`} xs={12} sm={2}>
            <a href="https://www.google.com/maps/place/Shuffletown+Park/@35.318126,-80.944476,17z/data=!3m1!4b1!4m5!3m4!1s0x8856a47e68188a05:0x22b1d175fa79951!8m2!3d35.318126!4d-80.942282"
              className={`${classes.footer__link__address}`}
              target='_blank' rel="noreferrer">
              <FontAwesomeIcon icon={solid('location-dot')} size='3x' className='mb-3' />
              <p>
                6800 Monroe rd. Charlotte, NC 28269
              </p>
            </a>
          </Col>
          <Col className={`${classes.footer__col__item} ps-5`} xs={12} sm={2}>
            <FontAwesomeIcon icon={solid('envelope')} size='3x' className='mb-3' />
            <p className={`${classes.link_styling} text-wrap`}>
              <a href='mailto:purposedriventrackclub@gmail.com' className={`${classes.link_styling} text-wrap`}>
                purposedriventrackclub@gmail.com
              </a>
            </p>
          </Col>
          <Col className={`${classes.footer__col__item} ps-5`} xs={12} sm={2}>
            <FontAwesomeIcon icon={solid('mobile-screen-button')} size='3x' className='mb-3' />
            <p>
              (704)390-8942
            </p>
          </Col>
          <Col className={`${classes.footer__col__item} pt-2 ps-5`} xs={12} sm={2}>
            <button className={`${classes.donate_button}`}>
              Donate
            </button>
          </Col>
        </Row>
      </Container>
      <Container className='d-flex justify-content-center gap-4 mb-2 d-sm-inline-flex'>
        <a className={`${classes.footer_link}`} href="/">HOME</a>
        <span>|</span>
        <a className={`${classes.footer_link}`} href="/register">REGISTER</a>
        <span>|</span>
        <a className={`${classes.footer_link}`} href="/about">ABOUT US</a>
        <span>|</span>
        <a className={`${classes.footer_link}`} href="/schedule">SCHEDULE</a>
        <span>|</span>
        <a className={`${classes.footer_link}`} href="/contact">CONTACT US</a>
      </Container>
      <center >
        <p className={`${classes.footer__copyright_text}`}>Ⓒ Copyright 2017  Purpose Driven NC Elite. All Rights Reserved.</p>
      </center>
    </Container>
  )
}

export default Footer