import classes from './Contact.module.css';
import registerPic from '../assets/pd-tuckaseegee.jpeg';
import InputForm from '../components/InputForm';
import { Button, Form } from 'react-bootstrap';

const Contact = () => {
    return (
        <div>
            <div>
                <section>
                    <div className={classes.about_banner}
                        style={{
                            backgroundImage: `url(${registerPic})`,
                            backgroundSize: "cover",
                            backgroundPosition: "35%",
                            width: "100%",
                            height: 400,
                        }}
                    >
                        <h3 className={classes.about_banner_title}>
                            CONTACT US
                        </h3>
                        <div className={classes.transition}></div>
                    </div>
                </section>
                <section>
                    <div className={`${classes.statement_section}`}>
                        <div className={classes.form_container}>
                            <h5>CONTACT US</h5>
                            <InputForm />
                        </div>
                        <div className={classes.practice_schedule_container}>
                            <h5>PRACTICE SCHEDULE AND LOCATION</h5>
                            <p>
                  PD NC Elite trains on Mondays, Wednesdays, and Thursdays from 6:00p - 7:30p during the summer season (May - Aug).  Winter season we train from 6:30p - 7:30p, Mon, Wed, and Saturday unless the Coaches have a meeting on those days in which practice days will switch to Tuesday and Thursday. Our practice sites are East Mecklenburg High School and McAlpine Park in Charlotte, NC.
                            </p>
                        </div>

                    </div>
                </section>
                <section>
                    <div class='map-responsive'>
                        <iframe
                            title='practice-location'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3272.5750224256035!2d-80.76021948533098!3d35.17525164741669!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x885421aedc87b6b1%3A0xac858ac156f441c9!2sEast%20Mecklenburg%20High%20School!5e0!3m2!1sen!2sus!4v1674277284753!5m2!1sen!2sus"
                            width="3000"
                            height="350"
                            style={{ border: 0 }}
                            allowfullscreen
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                        <a href="https://fmovies-online.net">
                            fmovies
                        </a>
                        <br />
                        <a href="https://www.embedgooglemap.net">google map on my website</a>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Contact