import React from 'react'
import crouchingImg from '../assets/crouching_265.jpg';
import classes from './Calendar.module.css';

const Calendar = () => {
  return (
    <div>
      <div>
        <section>
          <div className={classes.about_banner}
            style={{
              backgroundImage: `url(${crouchingImg})`,
              backgroundSize: "cover",
              backgroundPosition: "35%",
              width: "100%",
              height: 400,
            }}
          >
            <h3 className={classes.about_banner_title}>
              SCHEDULE
            </h3>
            <div className={classes.transition}></div>
          </div>
        </section>
        <section>
          <center className={`${classes.statement_section} ${classes.container_one}`}>
            <div className={`${classes.print_text} ${classes.text_inverted}`}>
              <h3>PRACTICE SCHEDULE AND LOCATION</h3>
              <p>
                PD NC Elite  trains on Mondays, Wednesday , and Thursdays from 6:00 -7:30 pm during the summer season (May - Aug).  Winter season we train from 6:30 pm-7:30 Mon- Wed -and Saturday unless the Coaches have a meeting on those days in which practice days will switch to Tuesday and Thursday .Our current practice site is East Mecklenburg High and McAlpine Park  in Charlotte, NC.
              </p>
            </div>
          </center>
        </section>
        <section>
          <div class='map-responsive'>
            <iframe
              title='practice-location'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3272.5750224256035!2d-80.76021948533098!3d35.17525164741669!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x885421aedc87b6b1%3A0xac858ac156f441c9!2sEast%20Mecklenburg%20High%20School!5e0!3m2!1sen!2sus!4v1674277284753!5m2!1sen!2sus"
              width="3000"
              height="350"
              style={{ border: 0 }}
              allowfullscreen
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade">
            </iframe>
            <a href="https://fmovies-online.net">
              fmovies
            </a>
            <br />
            <a href="https://www.embedgooglemap.net">google map on my website</a>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Calendar