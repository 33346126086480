import Carousel from 'react-bootstrap/Carousel';
import React, { useState } from 'react';
import classes from './BannerCarousel.module.css';

const BannerCarousel = ({ image1, image2, image3 }) => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (

        <Carousel activeIndex={index} onSelect={handleSelect} interval={4000}>
            <Carousel.Item>
                <img
                    className={`d-block w-100 ${classes.banner_pic}`}
                    src={image1}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className={`d-block w-100 ${classes.banner_pic}`}
                    src={image2}
                    alt="Second slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className={`d-block w-100 ${classes.banner_pic}`}
                    src={image3}
                    alt="Third slide"
                />
            </Carousel.Item>
        </Carousel>

    )
}

export default BannerCarousel