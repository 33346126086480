import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import classes from './Register.module.css';
import registerPic from '../assets/pd-tuckaseegee.jpeg';
import ContactModal from '../components/ContactModal';

const Register = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <div>
        <section>
          <div className={classes.about_banner}
            style={{
              backgroundImage: `url(${registerPic})`,
              backgroundSize: "cover",
              backgroundPosition: "35%",
              width: "100%",
              height: 400,
            }}
          >
            <h3 className={classes.about_banner_title}>
              REGISTER
            </h3>
            <div className={classes.transition}></div>
          </div>
        </section>
        <Container className='d-flex justify-content-start'>
          <ContactModal show={show} handleClose={handleClose} name='Coach Irving Hunter' phone='704-408-8357' email='coachdoc44@gmail.com' />
          <ContactModal show={show} handleClose={handleClose} name='Coach LaSonja Collins' phone='704-390-8942' email='purposedriventrackclub@gmail.com' />
        </Container>
        <section>
          <div className={`${classes.statement_section} ${classes.statement_section_inverted}`}>
            <div>
              <div className='mb-4'>
                <h5>
                  Click <a href="https://docs.google.com/forms/d/e/1FAIpQLSdeNs69xKs8guONLXEiPmNtkwOxUigd6mN2nMjWB5St8KR7nQ/viewform" target='blank'>HERE</a> to register
                </h5>
              </div>
              <div >
                <h5 className='text-start fs-4'>Program Age Range</h5>
                <p className='text-start mb-4'>Boys and Girls 7yrs-19yrs and up</p>
              </div>
              <div className='mb-4'>
                <h5 className='text-start fs-4'>Program Cost</h5>
                {/* <h5>Cost:</h5> */}
                <h5>For pricing info, contact <span
                  onClick={handleShow}
                  className={classes.coaches_modal_link}
                >
                  Coach Hunter
                </span> or <span
                  onClick={handleShow}
                  className={classes.coaches_modal_link}
                >
                    Coach Collins
                  </span>
                </h5>
              </div>
              <div>
                <h5>Payment Options:</h5>
                <h5>Cash or Cash App -- <strong>@pdelitetc</strong></h5>
              </div>
              <div className='mb-4'>
                <p className='text-start mt-3'>Registration Includes:</p>
                <ul>
                  <li>Uniform (top)</li>
                  <li>AAU and USATF Membership fees</li>
                </ul>
                <p className='text-start'>** Track meet registraion fees are not covered</p>
                <p className='text-start'>** Track meet registration fees can be given to coaches</p>
              </div>
              <div>
                <h5 className='text-start fs-4'>Non Athletic Membership Fees</h5>
                <p className='text-start'>Purchase a Non-Athlete membership for the following people: Administrator, Bench Personnel, Coach, Instructor, Manager, Official, Team Leader, Tournament Director, Volunteer, and Other.</p>
                <h5>** We are 100% Non-Profit -All fees will go towards your child's uniform, membership and entry fees</h5>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Register