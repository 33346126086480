import React from 'react';
import BannerCarousel from './BannerCarousel';
import classes from "./Banner.module.css";
import bannerImage from "../assets/track-photo.jpg";
import thirdPic from "../assets/winning-pic.png";
import aboutImage from "../assets/about-photo.png";

const Banner = () => {

  return (
    <div className={classes.banner}>
      <div className={classes.banner_title_bg}>
        <h1 className={classes.banner_title}>
          PURPOSE DRIVEN <br /> NC ELITE
        </h1>
      </div>
      <BannerCarousel image1={bannerImage} image2={aboutImage} image3={thirdPic} />
    </div>
  )
}

export default Banner