import React from 'react';
import Modal from 'react-bootstrap/Modal';
import classes from './ContactModal.module.css';

const ContactModal = ({ show, handleClose, name, phone, email }) => {
  return (
    <Modal
      show={show}
      // size="md"
      onHide={handleClose}
      // backdrop="static"
      keyboard={false}
      centered
    // className={classes.modal_size}
    >
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='text-start'>Phone: {phone}</p>
        <p className='text-start'>Email: {email}</p>
      </Modal.Body>
    </Modal>
  )
}

export default ContactModal;