import React from 'react'
import classes from "./About.module.css";
import aboutImage from "../assets/about-photo.png";
import coachesImg from "../assets/coaches.jpg";
import { Col, Row } from 'react-bootstrap';

const About = () => {
  const names = ["Collins", "Hunter", "Mike", "Bryan", "Perkins"]

  return (
    <div>
      <div>
        <section>
          <div className={classes.about_banner}
            style={{
              backgroundImage: `url(${aboutImage})`,
              backgroundSize: "cover",
              backgroundPosition: "35%",
              width: "100%",
              height: 400,
            }}
          >
            <h3 className={classes.about_banner_title}>
              ABOUT US
            </h3>
            <div className={classes.transition}></div>
          </div>
        </section>
        <section>
          <center className={`${classes.statement_section} ${classes.container_one}`}>
            <div className={`${classes.print_text} ${classes.text_inverted}`}>
              <p>
                Purpose Driven NC Elite Club  was established Spring of 2017 by LaSonja Collins with the support of the Community -Together, this group embarked on a mission to build a nationally recognized program on a foundation of quality instruction, technical skill development, superior athletic performance, community and corporate support, and efficient administrative/operational processes. We are a private, membership-based organization. Our goal is to provide athletes with an affordable program that will teach and help build a positive member of our community .
              </p>
            </div>
          </center>
        </section>
        <section>
          <center className={`${classes.statement_section} ${classes.statement_section_inverted}`}>
            <div className={classes.print_text}>
              <h3>TRAINING PHILOSOPHY</h3>
              <p>
                Our athletes train in groups based on events (short sprints, long sprints, distance, and specialists - jumps/throws). We build our training calendars around 4 mesocycles - general preparation, specific preparation, pre-competition preparation, and competition preparation.
              </p>
              <p>
                We focus on developing the technical, physical, and psychological skills that ultimately improve athlete performance. Our staff uses a set of instructional cues to help athletes acquire the physical and mental acumen needed to improve.
              </p>
            </div>
          </center>
        </section>
        <section>
          <div className={classes.about_coaches_pic}
            style={{
              backgroundImage: `url(${coachesImg})`,
              backgroundSize: "cover",
              width: "100%",
              height: 600,
            }}
          >
            <div className={classes.transition}></div>
            <h3 className={classes.about_coach_title}>
              MEET OUR COACHES
            </h3>
          </div>
        </section>
        <section>
          <center className={`${classes.statement_section} ${classes.statement_section_inverted}`}>
            <Row>
              <Col className='fs-6 text-start ps-4'>
                <h4 className='mb-3'>Coaches:</h4>
                <Row>
                  <Col>
                    <ul className='fs-5 text-start'>
                      <li>LaSonja Collins</li>
                      <li>Irving Hunter</li>
                      <li>Paul Brown</li>
                      <li>Mark Williams</li>
                    </ul>
                  </Col>
                  <Col className='fs-6 text-start'>
                    {/* <h4 className='mb-3'>Coaches:</h4> */}
                    <ul className='fs-5 text-start'>
                      <li>Bryan Davis</li>
                      <li>Mike Zimmerman</li>
                      <li>Darryl Chavers</li>
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col className='fs-6 text-start'>
                <h4 className='mb-3'>Affiliation-2022-2023 AAU - Level 2 Club</h4>
                <h5>All coaches involved have: </h5>
                <ol className='fs-5 text-start'>
                  <li>Are full background checked</li>
                  <li>NFHS Fundamentals of Coaching</li>
                  <li>Head Coach has Level 1 USATF certified with CPR, CDLS</li>
                  <li>SAFE SPORT Course</li>
                </ol>
              </Col>
            </Row>
          </center>
        </section>
      </div>
    </div>
  )
}

export default About
